@font-face {
  font-family: 'Apercu';
  src: url('./fonts/Apercu.woff2') format('woff2'),
    url('./fonts/Apercu.woff') format('woff'),
    url('./fonts/Apercu.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Apercu';
  src: url('./fonts/Apercu-Light.woff2') format('woff2'),
    url('./fonts/Apercu-Light.woff') format('woff'),
    url('./fonts/Apercu-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Apercu';
  src: url('./fonts/Apercu-Bold.woff2') format('woff2'),
    url('./fonts/Apercu-Bold.woff') format('woff'),
    url('./fonts/Apercu-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

/*********ANT MENU STYLING STARTS***********/

.menuRoot {
  width: 256px;
  position: absolute;
  top: 0px;
  z-index: 1;
  max-height: 100%;
  overflow-x: scroll;
}

.ant-switch {
  position: absolute !important;
  right: 10px !important;
  top: 13px !important;
  background-color: #000b3d !important;
}

.ant-menu {
  overflow-x: hidden;
  color: #000b3d !important;
  font-family: 'Apercu';
  font-style: normal;
  font-weight: normal;
}

.ant-menu-submenu-selected {
  color: #0014cc !important;
}

.ant-menu-submenu-open.ant-menu-submenu-active
  > div
  > i.subMenuIcon
  > svg
  > path {
  fill: #0014cc;
}
.ant-menu-inline {
  border-right: 0px !important;
}
.subMenuFirstStep {
  border-bottom: 1px solid lightgray;
}
.propertyItem {
  display: flex !important;
}

.ant-menu-item-selected.propertyItem {
  background-color: white !important;
  color: #000b3d;
}
.ant-switch-checked {
  background-color: #0014cc !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0014cc !important;
  border-color: #0014cc !important;
}
.propertyColor {
  height: 8px;
  width: 8px;
  margin-top: 16px;
  margin-right: 6px;
}

.propertyColor.blue {
  background-color: #0014cc;
}
.propertyColor.darkBlue {
  background-color: #000b3d;
}
.propertyColor.orange {
  background-color: #ff5039;
}
.propertyColor.pink {
  background-color: #fca4a1;
}
.propertyColor.red {
  background-color: #fa4747;
}
.propertyColor.borderBlue {
  background-color: #0014cc;
}
.propertyColor.rectangle {
  background: url(./assets/Rectangle-strok.png);
  background-size: contain;
}

.propertyItem.selected,
.propertyItem.selected:hover {
  color: #0014cc !important;
}

.ant-menu-item-selected.switchItem.selected {
  background-color: white !important;
  color: #000b3d;
}
.switchItem.selected {
  opacity: 1 !important;
}
.ant-menu-item.switchItem {
  color: #000b3d;
  opacity: 0.4;
}
.ant-menu-submenu-title {
  background-color: white !important;
}
.ant-menu-item-active {
  background-color: white !important;
}
.ant-menu-submenu-title:hover {
  color: #0014cc !important;
}
.ant-menu-item-active.switchItem:hover {
  color: #000b3d !important;
}
.ant-menu-item-active.propertyItem:hover {
  color: #0014cc !important;
}
.ant-menu-submenu-open.subMenuFirstStep {
  background-color: white !important;
  color: #0014cc !important;
}

.citiesSubMenu.ant-menu-submenu-open {
  color: #0014cc !important;
}
.ant-menu-submenu-selected {
  color: #000b3d !important;
}
.subMenuIcon.selected > svg > path {
  fill: #0014cc;
}
.subMenuFirstStep.selected {
  color: #0014cc !important;
}
.subMenuFirstStep.selected {
  color: #0014cc !important;
}
/* .ant-menu-item-selected {
  color: #0014cc !important;
  background-color: white !important;
} */
li.ant-menu-item.subMenuItem.ant-menu-item-only-child.ant-menu-item-selected {
  color: #0014cc !important;
}

.subMenuItem:hover {
  color: #0014cc !important;
}
.ant-menu-item-selected.subMenuItem {
  background-color: white !important;
  color: #000b3d !important;
}
.ant-menu-submenu-selected.citiesSubMenu {
  color: #0014cc;
}
.switchItem.selected,
.switchItem.selected:hover {
  color: #0014cc !important;
}
.ant-menu-item-selected {
  background-color: white !important;
}

.ant-menu-item::after {
  border-right: 0px !important;
}

.ant-menu-submenu-title {
  padding-left: 38px !important;
}
.ant-menu-item {
  padding-left: 38px !important;
}
.subMenuItem {
  font-size: 12px;
  height: 30px !important;
  border: none !important;
}
.menuButton {
  background-color: #000b3d !important;
  border-color: #000b3d !important;
  height: 40px !important;
  width: 40px !important;
  padding: 0px !important;
}
.ant-checkbox {
  position: absolute !important;
  right: 15px !important;
  top: 10px !important;
}

.subMenuIcon {
  position: absolute !important;
  left: 5px !important;
  top: 5px !important;
}
.ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  top: 40% !important;
  transform: all 0.3 ease-in-out;
}

.ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  content: '';
  position: absolute;
  background: unset !important;
  background-image: unset !important;
  width: 0 !important;
  height: 0 !important;
  border-style: solid;
  border-width: 6px 0 0 6px;
  border-color: #000b3d transparent transparent transparent;
  transition: transform 0.3s ease-in-out !important;
  transform: rotate(135deg) !important;
  border-radius: 0 !important;
  left: 0;
}

.ant-menu-submenu-open
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before {
  content: '';
  position: absolute;
  background: unset !important;
  background-image: unset !important;
  width: 0 !important;
  height: 0 !important;
  border-style: solid;
  border-width: 6px 0 0 6px;
  border-color: #000b3d transparent transparent transparent;
  transition: transform 0.3s ease-in-out !important;
  transform: rotate(-45deg) !important;
  border-radius: 0 !important;
  left: 0;
}

.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after {
  content: none !important;
}

/*********ANT MENU STYLING ENDS***********/

/******MAPBOX OVERRIDE CSS STARTS******/

.mapboxgl-ctrl-top-left {
  padding-left: 256px;
}
#geocoder-search {
  position: absolute;
  left: 0px;
  top: 0px;
  font-family: 'Apercu' !important;
  border-bottom: 1px solid lightgray;
}

.directionsButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 42px;
  background-color: #000b3d;
  position: absolute;
  right: 0px;
  transition: all 0.2s ease-in-out;
}
.ant-menu-item.search-dir {
  margin: 0px !important;
  height: 42px !important;
  overflow: visible !important;
}
.ant-menu-item a::before {
  position: relative !important;
}
.directionsButton.selected {
  background-color: #0014cc;
}
.ant-menu-inline-collapsed {
  width: 0px !important;
}
.ant-menu-submenu-popup {
  display: none !important;
}

.mapbox-directions-profile label {
  border-radius: 0px !important;
  font-family: 'Apercu' !important;
}

#geocoder-search .mapboxgl-control-container {
  z-index: 999;
}
#geocoder-search .mapboxgl-ctrl-geocoder--icon {
  height: 20px;
}
#geocoder-search .mapboxgl-ctrl-geocoder--input {
  padding-left: 37px !important;
}
#geocoder-search .mapboxgl-ctrl-geocoder {
  font-family: 'Apercu' !important;
  margin: 0px !important;
  border-radius: 0px !important;
  min-width: 215px !important;
  z-index: 99 !important;
  box-shadow: none !important;
}

.mapboxgl-ctrl-geocoder {
  font-family: 'Apercu' !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  min-width: 260px !important;
}
.mapbox-directions-component-keyline {
  box-shadow: none !important;
  border-radius: 0px !important;
}

.mapbox-improve-map {
  display: none;
}
.mapboxgl-popup {
  max-width: 300px !important;
}

.mapboxgl-popup-content {
  background: #ffffff !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 0px !important;
  /* min-width: 304px; */
}
.mapboxgl-popup-close-button {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 32px;
  width: 32px;
}
.popUpDiv.retail + .mapboxgl-popup-close-button,
.popUpDiv.project + .mapboxgl-popup-close-button {
  color: #000;
}

.mapboxgl-ctrl-attrib-button {
  display: none;
}

/******MAPBOX OVERRIDE CSS ENDS******/

.popUpDiv {
  /* min-width: 304px; */
  font-family: 'Apercu';
  color: #000b3d !important;
}

.popUpHeading {
  padding: 10px 54px;
  font-size: 14px;
  line-height: 1;
  min-height: 32px;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-position: top 3px left 15px;
}
.popUpHeading.retail {
  background-color: #ff5039;
  color: #000b3d;
  background-image: url('./assets/officeIcon.svg');
}
.popUpHeading.office {
  background-color: #000b3d;
  color: #ffffff;
  background-image: url('./assets/retailIcon.svg');
}
.popUpHeading.project {
  background-color: #fca4a1;
  color: #000b3d;
  background-image: url('./assets/projectIcon.svg');
}
.popUpHeading.strok {
  background-color: #0014cc;
  color: #ffcdd2;
  background-image: url('./assets/strokIcon.svg');
}
.addressParagraph,
.builtParagraph {
  font-weight: bold;
  margin: 0px 0px 5px;
  padding: 0px;
}
.popUpInfo {
  background: #ffffff;
  /* min-width: 304px; */
  padding: 15px 20px 15px 16px;
  display: flex;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}
.popUpInfo.project {
  display: block;
}
.popUpInfo.project > a {
  color: #0014cc;
}
.popUpInfo.strok {
  display: block;
}
.popUpInfo.strok > a {
  color: #0014cc;
}

.address,
.built {
  padding-right: 5px;
  width: 50%;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}
